import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Loader } from 'google-maps';
import { get } from 'lodash';

$(document).ready(async () => {

  hideBlocks();
  const googleKey = 'AIzaSyByA9IDMQRZyQ_IBrHOHl19cjpoJhDNWTY';
  const googleLoader = new Loader(googleKey, { libraries: ['places'] });
  const google = await googleLoader.load();

  let placeSearch;
  let autocomplete;
  let is_bundle = false;
  let address;
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  const { delivery_address } = gon.order;
  autocompleteMapBox();
  intervals(gon.start_time, gon.end_time);
  // autocomplete = new google.maps.places.Autocomplete(
  //   document.getElementById('delivery_address')
  // );
  //
  // autocomplete.addListener('place_changed', fillInAddress);
  setTimeout(() => {
    document.getElementById('delivery_address').value = delivery_address;
  }, 200);

  $('#delivery_address').on('input', function() {
    if (!$(this).val()) {
      document.getElementById('zip_code').value = '';
    }
  });
  /** autocomplete with mapBox * */
  function autocompleteMapBox() {
    mapboxgl.accessToken = gon.map_box_access_token;

    const map = new mapboxgl.Map({
      container: 'map_box',
      style: 'mapbox://styles/mapbox/streets-v9',
      center: [-118.6919161,34.0207305],
      zoom: 13
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      placeholder: 'Deliver address',
      countries: 'us',
      filter: function(item) {
        return item.context
          .map(function(i) {
            return (
              i.id.split('.').shift() === 'region' &&
              (i.text === 'California' || i.text === 'New York')
            );
          })
          .reduce(function(acc, cur) {
            return acc || cur;
          });
      },
      name: 'deliver_address',
      mapboxgl: mapboxgl
    });
    geocoder.on('result', function(e) {
      const { coordinates } = e.result.geometry;
      $('#lngId').val(coordinates[0]);
      $('#latId').val(coordinates[1]);
      $('#delivery_address').val(e.result.place_name);

      const { context } = e.result;
      document.getElementById('zip_code').value = '';
      $.each(context, function(index, value) {
        const context_type = value.id.split('.')[0];

        switch (context_type) {
          case 'postcode':
            document.getElementById('zip_code').value = value.text;
            break;
        }
      });
    });
    $('#geocoder').append(geocoder.onAdd(map));
    $('.mapboxgl-ctrl-geocoder--input').val($('#delivery_address').val());
    $('.mapboxgl-ctrl-geocoder--input').attr('autocomplete', 'off');
  }

  function fillInAddress() {
    const place = autocomplete.getPlace();
    const addressInfo = {};

    $('#latId').val(place.geometry.location.lat());
    $('#lngId').val(place.geometry.location.lng());

    if (place.address_components && place.address_components.length) {
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (addressType === 'street_number') {
          addressInfo.street_address =
            place.address_components[i][componentForm[addressType]];
        }
        // Street Name
        if (addressType === 'route') {
          addressInfo.street_address = `${addressInfo.street_address} ${
            place.address_components[i][componentForm[addressType]]
          }`;
        }
        // City
        if (addressType === 'locality') {
          addressInfo.city =
            place.address_components[i][componentForm[addressType]];
        }
        // State
        if (addressType === 'administrative_area_level_1') {
          addressInfo.state =
            place.address_components[i][componentForm[addressType]];
        }

        // Zip
        if (addressType === 'postal_code') {
          addressInfo.zip =
            place.address_components[i][componentForm[addressType]];
        }
      } // end for loop

      document.getElementById('zip_code').value = addressInfo.zip;
    }
  }
  function intervals(startString, endString) {
    let current = new Date()
    let start = current.toLocaleDateString() + ' ' + startString
    let end = current.toLocaleDateString() + ' ' + endString
    start = moment(start);
    end = moment(end);
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
    let result = [];
    while (start < end) {
      result.push(start.format('HH:mm'));
      start.add(15, 'minutes');
    }
    $('#schedule_date').html('');
    $.each(result, function(index, value) {
       let order_time = current.toLocaleDateString() + ' ' + value
      $('#curbside_time').append(`<option  ${order_time == gon.order.curbside_time ? "selected" : ''} value="${value}">${value}</option>`);
    });
  }
  function checkRequiredFields() {
    if (
        ($('#store_location').val() !== null && $('#store_location').val() != 0) &&
        (($('#edit_order').find('input.mapboxgl-ctrl-geocoder--input').val() || $('#in_store_pickup').is(':checked')) || $('.save_reason').attr('data-cost') ) &&
        ($('#zip_code').val()) &&
        ($('#order_origin').val() != 0 || $('#in_store_pickup').is(':checked')) &&
        ($('#table_body').children().length !== 0)
      ) {
        $('#submit_button').prop("disabled", false);
      } else{
        $('#submit_button').prop("disabled", "disabled");
      }
  }

  $('.mapboxgl-ctrl-geocoder--button').on('click', function () {
    updateCosts();
  })

  $('#edit_order').find('input.mapboxgl-ctrl-geocoder--input').on('change', function(){
    updateCosts();
  });

  $('#order_origin').on('change', function(){
    checkRequiredFields();
  });
  $('#cost_shipping').on('change', function() {
    updateCosts();
  });
  $('#store_location').prop('disabled', true);
  $('#store_marketplace').prop('disabled', true);

  $('#storeLocationId').on('change', function () {
    $.get('/marketplace_by_location', {location_id: $('#store_location').val()}).done(data => {
      $('#store_marketplace').html('')
      if(data.marketplaces.length){
        $('#storeMarketplaceId').css('display', 'block')
      }else{
        $('#storeMarketplaceId').css('display', 'none')
      }
      $.each(data.marketplaces, function (index, val) {
        $('#store_marketplace').append('<option value="' + val.id + '">' + val.name + '</option>')

      });
      $.each(order_items_array, function (index, value) {
        $.get('/product_details', {product_id: value.product_id, marketplace_id: $('#store_marketplace').val(), is_promo: false}).done(data => {
          updateItemUnitPrice(data);
        });
      });
    });
  });

  $('#store_marketplace').on('change', function () {
    $.each(order_items_array, function (index, value) {
      if (value) {
        $.get('/product_details', {product_id: value.product_id, marketplace_id: $('#store_marketplace').val(), is_promo: false}).done(data => {
          updateItemUnitPrice(data)
        });
      }
    });
  });
$('#expiration_date').datepicker();

$("#medical_order").change(function() {
  if(this.checked) {
    $('#medical_id').css( "display", "block");
  }else{
    $('#medical_id').css( "display", "none");
  }
});

var maxDate = moment().add(14, 'day');
$('.datepicker').datetimepicker({
  format: 'YYYY-MM-DD HH:mm:ss',
  minDate: 0,
  maxDate: maxDate,
  defaultDate: null
});

  $('#errors').hide();
  $('#errors_edit').hide();
  $('#order_errors').hide();
  const currency_formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  let order_total_cost = gon.order.total_cost;

  $('#cancelForm').submit(e => {
    let reason = $('#reason').val();
    reason = reason.replace(/ /g, '');
    if (reason.length <= 4) {
      alert('You must provide a reason more than 5 characters!');
      return false;
    }
  });

  let { zip } = gon.order;
  let { quantity, subtotal } = gon;
  const order_id = $('#order_id').val() || gon.order.id;
  const customer_id = get(gon, 'order.app_user.id');
  var promo_code_id = $('#promoId').val();
  let order_items_array = [];
  const addressInfo = {};
  const itemIndex = 0;
  let excludedSubTotal = 0.0;
  let delivery_cost = gon.order.delivery_cost || 0;
  let order_discount = gon.order.discount_amount || 0;
  let reward_discount = gon.order.discount_amount_reward || 0;

  let discount_value = gon.order.discount_amount || 0;
  let discount_type = gon.order.discount_type || 0;
  let is_delivery_fee_removed = false;
  let is_shipping = gon.order.is_shipping;
  if(discount_type != 0 && discount_type === 'percentage'){
    discount_value = gon.order.discount_percent
  }

  let has_tax = false;
  const cc_fee = gon.order.cc_charge || 0;
  let debit_fee = gon.order.debit_fee || gon.order.pay_tender_fee || gon.order.aeropay_fee || 0;
  let paid_cash_balance = gon.order.cash_balance_paid || 0;
  let weedmap_fee = gon.order.weedmap_fee || 0;
  let fee_amount = gon.order.additional_charge || 0;
  const CC_PERCENT = gon.store.cc_percent || 0;
  const discount = gon.order.discount_amount || 0;
  const total = gon.order.total_cost || 0;
  let exciseTax = gon.order.excise_tax || 0;
  let recreationalFee = gon.order.non_medical_fee || 0;
  let salesTax = gon.order.sales_tax || 0;
  let city_tax_amount = gon.order.city_tax || 0;
  let business_city_tax = gon.order.business_city_tax || 0;
  let cityTaxId = gon.order.city_tax_id || 0;

  const geocoder = new google.maps.Geocoder();
  let tax_percent = get(gon, 'order.tax.value') || 0;
  const county_seat = '';
  let tax_county = '';
  let tax_city = '';
  const currencyFormatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD'
  });
  let inStorePickup = gon.order.in_store_pickup;
  let isCurbside = gon.isCurbside;
  let is_medical_order = gon.order.medical_order;
  let not_removed_items_array;
  let taxEl;
  let totalCostTax;

  /**
   * Zip Code
   * */
  document.querySelector('#zip_code').addEventListener('change', e => {
    zip = e.target.value;
  });

  // ========================== Grab initial order info ==========================

  order_items_array = gon.is_exchanged ? [] : JSON.parse(gon.order_items_html);
  if (weedmap_fee > 0) {
    $('#apply_weedmap_fee_button').hide();
    $('#remove_weedmap_fee_button').show();
  }

  if (delivery_cost > 0) {
    $('#add_delivery_fee_button').hide();
    $('#remove_delivery_fee_button').show();
  }

  $('#zip_code').keyup(function () {
    var zip_code_value = [{ mask: '#####' }];
    $('#zip_code').inputmask({
      mask: zip_code_value,
      placeholder: "",
      definitions: {
        '#': { validator: '[0-9]', cardinality: 1 }
      }
    });
    updateCosts();
});

  $('#cc_fee_button').click(() => {
    if (subtotal == 0) {
      alert('Please enter order items before calculating Credit Card fee!');
      return;
    }

    let new_sub = 0;
    for (let i = 0; i < order_items_array.length; i++) {
      if (order_items_array[i].action != 'Delete') {
        new_sub += order_items_array[i].cost;
      }
    }

    updateCosts();
  });

  $('input[type=radio][name=payment_type]').change(function() {
    switch ($(this).val()) {
      case 'Cash':
        $('#paymentMessage').css('display', 'none');
        debit_fee = 0.0;
        $('#debit_fee_label').text(currencyFormatter.format(debit_fee));
        $('#paytender_fee_label').text(currencyFormatter.format(debit_fee));
        $('#aeropay_fee_label').text(currencyFormatter.format(debit_fee));
        break;
      case 'Debit':
        $('#paymentMessage').css('display', 'none');
        debit_fee = parseFloat(gon.store.debit_card_fee, 10);
        break;
      case 'Paytender':
        $('#paymentMessage').css('display', 'none');
        debit_fee = parseFloat(gon.store.debit_card_fee, 10);
        break;
      case 'Aeropay':
        $('#paymentMessage').css('display', 'none');
        debit_fee = gon.marketplace_aeropay_fee ? parseFloat(gon.marketplace_aeropay_fee, 10) : parseFloat(gon.store.aeropay_fee, 10);
        break;
      case 'Ledgergreen':
        $('#paymentMessage').css('display', 'block')
        break;
    }
    updateCosts();
  });

  if ($('#remove_weedmap_fee_button').length > 0) {
    if (gon.order.weedmap_fee > 0) {
      $('#remove_weedmap_fee_button').show();
    } else {
      $('#remove_weedmap_fee_button').hide();
    }
    $('#remove_weedmap_fee_button').click(function() {
      if (subtotal == 0) {
        alert('Please enter order items before calculating Weedmap fee!');
        return;
      }
      $(this).hide();
      $('#add_weedmap_fee_button').show();
      weedmap_fee = 0.0;
      updateCosts();
    });
  }

  if ($('#add_weedmap_fee_button').length > 0) {
    if (!gon.order.weedmap_fee) {
      $('#add_weedmap_fee_button').show();
    } else {
      $('#add_weedmap_fee_button').hide();
    }

    $('#add_weedmap_fee_button').click(function() {
      if (subtotal == 0) {
        alert('Please enter order items before calculating Weedmap fee!');
        return;
      }
      $(this).hide();
      $('#remove_weedmap_fee_button').show();
      weedmap_fee = parseFloat(gon.store.weedmap_fee, 10);
      updateCosts();
    });
  }


  if ($('#remove_delivery_fee_button').length > 0) {
    if (delivery_cost > 0) {
      $('#remove_delivery_fee_button').show();
    } else {
      $('#remove_delivery_fee_button').hide();
    }

    $('#remove_delivery_fee_button').click(function() {
      $(this).hide();
      $('#apply_delivery_fee_button').show();
      delivery_cost = 0.0;
      is_delivery_fee_removed = true;
      updateCosts();
    });
  }
  if ($('#apply_delivery_fee_button').length > 0) {
    if (!delivery_cost) {
      $('#apply_delivery_fee_button').show();
    } else {
      $('#apply_delivery_fee_button').hide();
    }
    $('#apply_delivery_fee_button').click(function() {
      $(this).hide();
      $('#remove_delivery_fee_button').show();
      is_delivery_fee_removed = false;
      delivery_cost = parseFloat(gon.store.delivery_fee, 10);
      updateCosts();
    });
  }
  if ($('#apply_taxes').length > 0) {
    $('#apply_taxes').click(() => {
      const extax = Number($('#excisetax').val());
      const saletax = Number($('#salestax').val());
      if (!isNaN(extax) && extax !== '' && extax > 0) {
        exciseTax = extax;
      } else {
        alert('Please enter a positive number for Excise Tax !');
        return;
      }

      if (!isNaN(saletax) && saletax !== '' && saletax > 0) {
        salesTax = saletax;
      } else {
        alert('Please enter a positive number for Sales Tax !');
        return;
      }
      updateCosts();
    });
  }

  if (order_items_array.every(item => item.product.tax_excluded === true)) {
    $('#remove_delivery_fee').hide();
  }

  if (inStorePickup){
    $('#deliveryAddressId').hide();
    $('#apartmentNumberId').hide();
    $('#orderOriginId').hide();
    $('#remove_delivery_fee').hide();
  }
  if (!isCurbside){
    $('.curbside').hide();
    $('.curbside-checkbox').hide();
  }

  $('#in_store_pickup').click(() => {
    const pickupIsChecked = $('#in_store_pickup').is(':checked');
    const curbsideIsChecked = $('#is_curbside').is(':checked');
    if (pickupIsChecked == true) {
      $('#react-hot-toast').attr('disabled', 'disabled');
      $('#orderOriginId').hide();
      $('#deliveryAddressId').hide();
      $('.curbside-checkbox').show();
      if(curbsideIsChecked == true){
        $('.cash-curbside').hide();
        $('.curbside').show();
      }
      $('#apartmentNumberId').hide();
      $('#remove_delivery_fee').hide();
      var loc_id = $('#store_location').val();
      if(loc_id !== null && loc_id != 0 && loc_id != ''){
        $.get('/delivery_calc', { location_id: $('#store_location').val()}).done(data => {

          $("#zip_code").val(data.zip_code);
          delivery_cost = 0;
          updateCosts();
        });
      }

    } else {
      $('#order_delivery_address').removeAttr('disabled');
      $('#orderOriginId').show();
      $('#deliveryAddressId').show();
      $('#is_curbside').prop('checked', false);
      $('.curbside').hide();
      $('.curbside-checkbox').hide();
      $('.cash-curbside').css('display', 'initial');
      $('#is_curbside').checked = false;
      $('#apartmentNumberId').show();
      $('#remove_delivery_fee').show();
      delivery_cost = gon.store.delivery_fee;
      if (delivery_cost > 0) {
        $('#apply_delivery_fee_button').hide();
        $('#remove_delivery_fee_button').show();
      }
      $('#zip_code').val('');
      updateCosts();
    }
    inStorePickup = pickupIsChecked;
  });
  $('#is_curbside').click(() => {
    const curbsideIsChecked = $('#is_curbside').is(':checked');
    if (curbsideIsChecked == true) {
      $('.cash-curbside').hide();
      $('.curbside').show();
      $('#cash').attr('checked', false);
    } else {
      $('.cash-curbside').css('display', 'initial');
      $('.curbside').hide();
    }
  });
  $('#add_charge_button').click(() => {
    const fee_value = Number($('#additional_charge').val());

    if (!isNaN(fee_value) && fee_value !== '' && fee_value > 0) {
      fee_amount = fee_value;
      updateCosts();
    } else {
      alert('Please enter a positive number for fee amount!');
    }
    $('#additional_charge').val('');
  });

  $('#remove_charge_button').click(() => {
    fee_amount = 0;
    updateCosts();
  });

  function getTaxOfState() {
    $.get(
      '/taxes/get_tax_by_address',
      {
        tax_county,
        tax_city
      },
      data => {
        if (data.tax && data.tax.value) {
          tax_percent = data.tax.value;
        }
      }
    );
  }
	$('#medical_order').on('click', function(){
		updateCosts();
	})
  $('#medical').on('click', function(){
    updateCosts();
  })
  $('#state_medical').on('click', function(){
    updateCosts();
  })

  function updateCosts(update_values = true, is_promo_apply = true) {

    /*if(!$("#table_body tr").length) {
      return;
    }*/
    let map_box_access_token = gon.map_box_access_token;
    let zip_code_val = $('#zip_code').val();
    let promo_code_id = Number($('#promoId').val());
    let error_count = 0;
    let error_message = '';

    if (promo_code_id) discount_value = 0.0;
    if (!(zip_code_val === '' || zip_code_val === null) && $("#table_body tr").length) {

      if (zip_code_val.length != 5) {
        error_count++;
        error_message += '<li> Zip code must be five digits. </li>';
        $('#submit_button').attr('disabled', 'disabled');
      }else{
              $('.errors').hide();
              $('#order_errors').hide();
               $.get({
                   url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${zip_code_val}.json?access_token=${map_box_access_token}`,
                   async:false
                    }).done(data => {
                        var wrongZipCode = false;
                      for (var i = 0; i < data.features.length; i++) {
                        if (data.features[i].place_name.includes('California') || data.features[i].place_name.includes('New York')) {
                          wrongZipCode = false;
                          break;
                        }else{
                          wrongZipCode = true;
                        }

                      }
                     if(wrongZipCode){
                      error_count++;
                      error_message += '<li> Please enter the correct zip code. </li>';
                      $('#submit_button').attr('disabled', 'disabled');
                     }
                     checkRequiredFields();
                });
            }

              if (error_count > 0) {
                $('#order_errorsUl').html(error_message);
                $('.errors').html(error_message);
                $('.errors').show();
                $('#order_errors').show();
                return false;
               }else{
                $('#submit_button').prop("disabled", false);
               }
      }


    not_removed_items_array = [];
    order_items_array.forEach(item => {
      if (item.action !== 'Delete') {
        not_removed_items_array.push(item);
      }
    });

    if (not_removed_items_array.length !== 0){

        var productDetails = _.filter(not_removed_items_array, item => item.product.tax_excluded !== true).map(item => ({
        id: item.product_id,
        quantity: item.quantity,
        cost: item.product.sales_price ? item.product.sales_price : item.product.unit_price,
        cannabis: item.product.is_canabis,
        product_discount_price: item.product.product_discount_price,
        product_discount_quantity: item.product.product_discount_quantity,
        is_tax_excluded: item.product.tax_excluded
        })).flat();
      let location_is_shipping = $('#store_location')
        .find(':selected')
        .data('is-shipping');
      console.log('location_is_shipping', location_is_shipping);
      const minimum_price = $('#cost_shipping').find(':selected').data('minimum-price')

      getTaxOfState();
      var total_fee = delivery_cost + recreationalFee
      if (productDetails.length == 0){
        total_fee = 0.0
        $('#remove_delivery_fee').hide()
      }else if(is_shipping){
        delivery_cost = $('#cost_shipping').find(':selected').data('cost').toFixed(2);
        total_fee = delivery_cost + recreationalFee
      }else if(!inStorePickup && !is_delivery_fee_removed){
        delivery_cost = gon.order.delivery_cost
        total_fee = delivery_cost + recreationalFee
      }
      console.log('---------------------------------delivery_cost', delivery_cost)
      console.log('---------------------------------total_fee', total_fee)
      $('#submit_button').prop("disabled", true);
      is_medical_order = $('#medical_order').is(':checked')
      var medical_patient = '';
      if (is_medical_order) {
        medical_patient = $('input[name=medical_patient]:checked').val();
      }

      $.get('/orders/costs/delivery',
        {
          product_details: productDetails,
          order_items: JSON.stringify(productDetails),
          zip_code: $('#zip_code').val(),
          remove_limit: $('#remove_limit').is(':checked'),
          location_id: Number($('#store_location').val()),
          marketplace_id: Number($('#store_marketplace').val()),
          customer_id,
          promo_code_id,
          discount_amount: parseFloat(order_discount),
          discount_amount_reward: parseFloat(reward_discount),
          discount_type: discount_type,
          discount_value: parseFloat(discount_value),
          total_fee_value: total_fee,
          medical_order: is_medical_order,
          medical_patient:  medical_patient,
          in_store_pickup: $('#in_store_pickup').prop('checked'),
          order_id,
          is_promo_apply
        },
        data => {
          const costs = _.mapValues(data, item => {
            if (typeof item === 'number') item = parseFloat(item.toFixed(2));
            return item;
          });

          const itemsExcluded = _.filter(not_removed_items_array, item => item.product.tax_excluded == true).map(item => ({
            id: item.id,
            quantity: item.quantity,
            cost: item.cost,
            cannabis: item.product.is_canabis,
            product_discount_price: item.product.product_discount_price,
            product_discount_quantity: item.product.product_discount_quantity
          })).flat();

          if(data.order_items){
            updateCost(productDetails, data.order_items);
            updateCost(order_items_array, data.order_items, true)
          }
          if (itemsExcluded.length > 0){
            if(itemsExcluded.length > 0 && productDetails.length == 0){
              delivery_cost = 0;
              is_delivery_fee_removed = false;
              $('#remove_delivery_fee').hide();
            }else{
              if(inStorePickup){
                $('#remove_delivery_fee').hide();
              }else if(!is_delivery_fee_removed){
                $('#remove_delivery_fee').show();
                $('#remove_delivery_fee_button').show();
                $('#apply_delivery_fee_button').hide();
              }
              delivery_cost = inStorePickup ? 0.0 : $('#remove_delivery_fee_button').is(':hidden') ? 0.0 :gon.order.delivery_cost;
            }

            excludedSubTotal = parseFloat(
              _.reduce(
                itemsExcluded,
                (acc, item) => {
                  acc += item.cost * item.quantity;
                  return acc;
                },
                0
              ).toFixed(2)
            );
            productDetails= [...productDetails, ...itemsExcluded];
          }else{
            excludedSubTotal = 0;
          }

          const taxes = costs.line_items;
          city_tax_amount = costs.city_tax_amount || 0;
          business_city_tax = costs.business_city_tax || 0;
          cityTaxId = costs.city_tax_id;
          has_tax = costs.has_tax;
          order_discount = costs.discount_amount;
          reward_discount = costs.discount_amount_reward;
          recreationalFee = costs.recreational_fee_anount;

          if (!gon.store.dutchie_tax) {
                if (!gon.store.remove_excise_tax) {
                  exciseTax = costs.excise_tax;
                }
                salesTax = costs.salesTax;
          }

          if (costs.is_catalyst) {
            subtotal = parseFloat(
              _.reduce(
                taxes,
                (acc, item) => {
                  acc += item.outdoorPrice;
                  return acc;
                },
                0
              ).toFixed(2)
            );
            if (location_is_shipping && (parseFloat(minimum_price) <= parseFloat(subtotal))){
              delivery_cost  = 0
            }
            order_total_cost = (
              subtotal +
              excludedSubTotal +
              parseFloat(delivery_cost) +
              parseFloat(cc_fee) +
              parseFloat(fee_amount) +
              parseFloat(debit_fee) +
              parseFloat(weedmap_fee) +
              recreationalFee
            ).toFixed(2);
          } else {
            subtotal = parseFloat(
              _.reduce(
                productDetails,
                (acc, order_item) => {
                  acc += order_item.cost * order_item.quantity;
                  return acc;
                },
                0
              ).toFixed(2)
            );
            if (location_is_shipping && (parseFloat(minimum_price) <= parseFloat(subtotal))){
              delivery_cost  = 0
            }
            order_total_cost = (
              subtotal +
              parseFloat(delivery_cost) +
              parseFloat(fee_amount) +
              parseFloat(cc_fee) +
              parseFloat(debit_fee) +
              parseFloat(city_tax_amount) +
              parseFloat(weedmap_fee) +
              recreationalFee +
              salesTax +
              exciseTax -
              parseFloat(order_discount) -
              parseFloat(reward_discount) -
              parseFloat(paid_cash_balance)
            ).toFixed(2);
          }


          const currencyFormatter = new Intl.NumberFormat('en-us', {
            style: 'currency',
            currency: 'USD'
          });
          update_values = $('.save_reason').attr('data-cost') ? false : update_values;

          if( $('.save_reason').attr('data-cost') ) {
            checkRequiredFields();
          }

          if(update_values){
            console.log('delivery_costdelivery_costdelivery_costdelivery_cost', delivery_cost)
            $('#subtotal_label').text(currencyFormatter.format(subtotal));
            $('#delivery_cost_label').text(currencyFormatter.format(delivery_cost));
            $('#discount_cost_label').text(
              `- ${currencyFormatter.format(order_discount)}`
            );
            $('#reward_discount_cost_label').text(
              `- ${currencyFormatter.format(reward_discount)}`
            );
            $('#additional_label').text(
              currencyFormatter.format(parseFloat(fee_amount))
            );
            $('#recreational_fee_label').text(
              currencyFormatter.format(recreationalFee)
            );
            $('#city_tax_id').html(
              `${currencyFormatter.format(city_tax_amount)} (%${parseFloat(costs.city_tax)})`
            );
            $('#excise_tax_label').text(currencyFormatter.format(exciseTax));
            $('#sales_tax_label').text(currencyFormatter.format(salesTax));
            $('#total_cost_label').text(currencyFormatter.format(order_total_cost));

            if ($('#debit_fee_label').length && $('input[name=payment_type]:checked').val() == 'Debit') {
              $('#debit_fee_label').text(currencyFormatter.format(debit_fee));
              $('#paytender_fee_label').text(`$0.00`);
              $('#aeropay_fee_label').text(`$0.00`);
            }

            if ($('#paytender_fee_label').length && $('input[name=payment_type]:checked').val() == 'Paytender') {
              $('#paytender_fee_label').text(currencyFormatter.format(debit_fee));
              $('#debit_fee_label').text(`$0.00`);
              $('#aeropay_fee_label').text(`$0.00`);
            }

            if ($('#aeropay_fee_label').length && $('input[name=payment_type]:checked').val() == 'Aeropay') {
              $('#aeropay_fee_label').text(currencyFormatter.format(debit_fee));
              $('#debit_fee_label').text(`$0.00`);
              $('#paytender_fee_label').text(`$0.00`);
            }
            if ($('#weedmap_fee_label').length) {
              $('#weedmap_fee_label').text(currencyFormatter.format(weedmap_fee));
            }
            $('#reward-loyalty-checkout-data').attr('cart-subtotal-cents', parseFloat(parseFloat(order_total_cost) * 100 ));
            $('#reward-loyalty-cart-data').attr('data-free-product-points', parseFloat(parseFloat(reward_discount) * 10 ));
            $('#reward-loyalty-cart-data').attr('data-applied-coupon-points', parseFloat(parseFloat(reward_discount) * 10 ));
            checkRequiredFields();
            addTaxesToOrderItems(order_items_array, taxes);
          }
        }
      ).fail(data => {
        let error_count = 0;
        let error_message = '';
        const error = get(data, 'responseJSON.error.message', null);
        if (error) {
          error_count++;
          error_message += `<li>${error}</li>`;
        } else {
          if ($('#zip_code').val() === '' || $('#zip_code').val() === null) {
            error_count++;
            error_message += '<li>Zip Code is required.</li>';
          }
          if (
            $('#store_location').val() === '' ||
            $('#store_location').val() === null
          ) {
            error_count++;
            error_message += '<li>Store Location is required.</li>';
          }
        }
        if (error_count > 0) {
          $('#order_errorMsg').html(
            `There are ${error_count} error(s) in your submission.`
          );
          $('#order_errorsUl').html(error_message);
          $('.errors').html(error_message);
          $('.errors').show();
          $('#order_errors').show();
        } else {
          checkRequiredFields();
        }
      });
    }else {
      removeProduct();
    }
  }

  function removeProduct(){
    $('#submit_button').prop('disabled', 'disabled');
   $('#table_body').html('')
      not_removed_items_array = []
      $('#remove_delivery_fee_button').show();
      $('#subtotal_label').text('$0.00');
      $('#delivery_cost_label').text('$0.00');
      $('#total_cost_label').text('$0.00');
      // $("#cc_fee_label").text(currencyFormatter.format(cc_fee));
      $('#discount_cost_label').text('$0.00');
      $('#reward_discount_cost_label').text('$0.00');
      $('#additional_label').text('$0.00');
      $('#recreational_fee_label').text('$0.00');
      $('#excise_tax_label').text('$0.00');
      $('#sales_tax_label').text('$0.00');
      $('#city_tax_id').text('$0.00');
      $('#debit_fee_label').text('$0.00');
      $('#aeropay_fee_label').text('$0.00');
      $('#paytender_fee_label').text('$0.00');
      $('#ledger_green_fee_label').text('$0.00');
  }

  function getSinglePriceQtyTag(data, the_id, max) {
    if (max > 50) max = 50;

    let single_price_tag = `<select id="${data.id}" class="quantity_list">`;
    for (let i = 1; i <= max; i++) {
      single_price_tag += `<option value="${i}">${i}</option>`;
    }
    single_price_tag += '</select>';

    return single_price_tag;
  }
  function updateItemUnitPrice(data) {
    // Check if item already in cart before adding
    let exists_already = false;
    Object.keys(order_items_array).map(item => {
        $("#cost_" + order_items_array[item].product_id).html(data.unit_price.toFixed(2))
        order_items_array[item].cost = data.unit_price
        updateCosts(true)
    });
  }
  function getMultiPriceQtyTag(product, the_id) {

    let multi_price_tag = `<select id="${the_id}" class="quantity_list">`;

    if (product.gram_price !== null) {
      multi_price_tag += '<option value="1">1 GRAM</option>';
      multi_price_tag += '<option value="2">2 GRAMS</option>';
    }

    if (product.eigth_price !== null) {
      multi_price_tag += '<option value="3.5">1/8</option>';
    }

    if (product.quarter_price !== null) {
      multi_price_tag += '<option value="7">1/4</option>';
    }

    if (product.half_price !== null) {
      multi_price_tag += '<option value="14">1/2</option>';
    }

    if (product.oz_price !== null) {
      multi_price_tag += '<option value="28">OZ</option>';
    }

    multi_price_tag += '</select>';

    return multi_price_tag;
  }

  function getMinAmount(data) {
    if (data.gram_price !== null) {
      return data.gram_price;
    }
    if (data.eigth_price !== null) {
      return data.eigth_price;
    }
    if (data.quarter_price !== null) {
      return data.quarter_price;
    }
    if (data.half_price !== null) {
      return data.half_price;
    }
    if (data.oz_price !== null) {
      return data.oz_price;
    }
    return 0;
  }

  function getMinInfo(data) {
    if (data.gram_price !== null) {
      return {
        price: data.gram_price,
        grams: 1,
        unit: '1 GRAM'
      };
    }
    if (data.eigth_price !== null) {
      return {
        price: data.eigth_price,
        grams: 3.5,
        unit: '1/8'
      };
    }
    if (data.quarter_price !== null) {
      return {
        price: data.quarter_price,
        grams: 7,
        unit: '1/4'
      };
    }
    if (data.half_price !== null) {
      return {
        price: data.half_price,
        grams: 14,
        unit: '1/2'
      };
    }
    if (data.oz_price !== null) {
      return {
        price: data.oz_price,
        grams: 28,
        unit: 'OZ'
      };
    }
  }

  $('#brand_search')
    .autocomplete({
      source: '/brand_search',
      selectFirst: true,
      minLength: 0,
      scroll: true,
      classes: {
        'ui-autocomplete': 'highlight-autocomplite'
      },
      select(e, ui) {
        $('#brandId').val(ui.item.value);
        $('#brand_search').val(ui.item.label);
        return false;
      }
    })
    .focus(function() {
      // reset result list's pageindex when focus on
      window.pageIndex = 0;
      const obj = $('#brand_search').val();
      $('#brand_search').val('');
      $(this).autocomplete('search', '');
      $('#brand_search').val(obj);
    });

  $('#brand_search').on('input', function() {
    if (!$('#brand_search').val()) {
      $('#brandId').val('');
    }
  });

  $('#search')
    .autocomplete({
      selectFirst: true,
      minLength: 0,
      scroll: true,
      classes: {
        'ui-autocomplete': 'highlight-autocomplite'
      },
      source(request, response) {
        const brandId = $('#brandId').val();
        $.getJSON(
          '/product_search',
          {
            term: request.term,
            loc_id: $('#store_location').val(),
            brand_id: brandId,
            exchange: $('.save_reason').attr('data-cost') ? parseFloat($('.save_reason').attr('data-cost')) - parseFloat($('#current_cost').val()) : ''
          },
          response
        );
      },
      select(e, ui) {
        $('#submit_button').prop("disabled", "disabled");
        $.get('/product_details', { product_id: ui.item.value,  marketplace_id:  $('#store_marketplace').val(),location_id: $('#store_location').val(), is_promo: false})
          .done(data => {
            const pr_id = $('.cancel_exchange').attr('data-id');

            if(parseFloat($('.save_reason').attr('data-cost')) < parseFloat($('.cancel_exchange').attr('data-cost'))) {
              alert('You have already add exchange product!');
              return;
            }

            if (!gon.order.location) return;
            $.get('/is_in_stock', {
              product_id: ui.item.value,
              change_location: false,
              promo: false,
              location_id: Number($('#store_location').val())
            }).done(avaliData => {

              let pr_qty = avaliData.qty;
              let ex_cost = Math.floor((parseFloat($('.save_reason').attr('data-cost')) - parseFloat($('#current_cost').attr('value')))/parseFloat(data.unit_price));
              const data_qty = $('.exchange_count').val() && pr_qty >= ex_cost ? ex_cost : pr_qty;

              if (avaliData.available === true) {
                // check if item already in cart before adding
                let exists_already = false;
                if (order_items_array.length > 0) {
                  order_items_array.map(item => {
                    if (
                      item.product_id === data.id &&
                      item.action !== 'Delete'
                    ) {
                      exists_already = true;
                    }
                  });
                }
                if (exists_already === true) {
                  alert('That item has already been added!');
                } else {
                  addItem(data, data_qty);
                }
              } else {
                alert(
                  `${ui.item.label} is out of stock at the ${$(
                    '#store_location option:selected'
                  ).text()} location.`
                );
              }
            });
          })
          .done(() => {
            $('#search').val('');
          });
      }
    })
    .focus(function() {
      // reset result list's pageindex when focus on
      window.pageIndex = 0;
      const obj = $('#search').val();
      $('#search').val('');
      $(this).autocomplete('search', '');
      $('#search').val(obj);
    });

  $('#promo_product_search')
    .autocomplete({
      selectFirst: true,
      minLength: 0,
      scroll: true,
      classes: {
        'ui-autocomplete': 'highlight-autocomplite'
      },
      source(request, response) {
        const brandId = $('#brandId').val();
        $.getJSON(
          '/promo_product_search',
          {
            term: request.term,
            loc_id: $('#store_location').val(),
            brand_id: brandId
          },
          response
        );
      },
      select(e, ui) {
        $('#submit_button').prop("disabled", "disabled");
        $.get('/product_details', { product_id: ui.item.value, marketplace_id:  $('#store_marketplace').val(), is_promo: false}).done(data => {
          $.get('/is_in_stock', {
            product_id: ui.item.value,
            change_location: false,
            promo: true,
            location_id: $('#store_location').val()
          })
            .done(avaliData => {
              if (avaliData.available === true) {
                addSinlgeItem(data, avaliData.qty);
              } else {
                alert(
                  `${ui.item.label} is out of stock at the ${$(
                    '#store_location option:selected'
                  ).text()} location.`
                );
              }
            })
            .done(() => {
              $('#promo_product_search').val('');
              $('.quantity_list').on('change', function(e) {
                const quantity_value = this.value;
                const product_id = this.id;
                const quantity_text = $(
                  `#${product_id} option:selected`
                ).text();
                const item_to_change = order_items[product_id];
                updateLineItem(
                  quantity_text,
                  quantity_value,
                  item_to_change,
                  product_id
                );
              });
              $('.remove_button').click(function() {
                $('#submit_button').prop("disabled", "disabled");
                const to_remove = this.id.substring(7);
                removeItem(to_remove);
              });
            });
        });
      }
    })
    .focus(function() {
      // reset result list's pageindex when focus on
      window.pageIndex = 0;
      const obj = $('#search').val();
      $('#search').val('');
      $(this).autocomplete('search', '');
      $('#search').val(obj);
    });

  $('body').on('click', '.remove_button', function() {
    $('#submit_button').prop("disabled", "disabled");
    const order_item_id = parseInt(this.id.substring(7));

    let current_cost = 0;


    for (let i = 0; i < order_items_array.length; i++) {
      if (
        order_items_array[i].product_id == order_item_id &&
        order_items_array[i].action !== 'Delete'
      ) {
        if (order_items_array[i].action == 'Add') {
          order_items_array.splice(i, 1);
        } else {
          order_items_array[i].action = 'Delete';
          break;
        }
      }
    }

    $(`#row_${order_item_id}`).remove();

    if ($('.exchange_count').val()) {
      let pr_id = $('.cancel_exchange').attr('data-id');
      $('.old-product-' + pr_id).each(function() {
        let current_pr_id = $(this).attr('data-id');
        current_cost += parseFloat($(`#cost_${current_pr_id}`).attr('data-cost')) * parseInt($(`#cost_${current_pr_id}`).attr('data-qnty'));
      });
      $('#current_cost').val(current_cost);
    }

    let promo_code_id = Number($('#promo_id').val());
    var isUpdateComponents = (discount_value == null || typeof discount_value === 'undefined' || discount_value == 0.0 ) || promo_code_id != 0
    updateCosts(isUpdateComponents);
    if(discount_value != null && typeof discount_value !== 'undefined' && discount_value != 0.0) {
      setTimeout(applyDiscount, 1200);
    }
    hideBlocks();
  });

  $('#table_body').on('change', '.quantity_list', function() {
    const order_item_id = this.id;
    const new_quantity_text = $(`#${order_item_id} option:selected`).text();
    $(`#cost_${order_item_id}`).html();
    let current_cost = 0;
    let old_qty = $(`#cost_${order_item_id}`).attr('data-qnty');
    $(`#cost_${order_item_id}`).attr('data-qnty', parseInt(new_quantity_text));

    if ($('.exchange_count').val()) {
      let pr_id = $('.cancel_exchange').attr('data-id');
      $('.old-product-' + pr_id).each(function () {
        let current_pr_id = $(this).attr('data-id');
        current_cost += parseFloat($(`#cost_${current_pr_id}`).attr('data-cost')) * parseInt($(`#cost_${current_pr_id}`).attr('data-qnty'));
      });


      $(`#cost_${order_item_id}`).html('$' + (parseInt(new_quantity_text) * $(`#cost_${order_item_id}`).attr('data-cost')));
      $('#current_cost').val(current_cost);

      if (parseFloat($('.save_reason').attr('data-cost')) - parseFloat($('#current_cost').attr('value')) < 0) {
        alert('You don\'t have enough money.');
        $(`#${order_item_id}`).val(old_qty).trigger('change');
      }
    }

    // find product selected and update
      for (let i = 0; i < order_items_array.length; i++) {
        if (
          order_items_array[i].product_id == order_item_id &&
          order_items_array[i].action != 'Delete'
        ) {
          const costId = order_items_array[i].product_id;
          const new_quantity_int = parseInt(new_quantity_text);
          order_items_array[i].quantity = new_quantity_int;
          $(`#cost_${costId}`).text(
            `$${(
              new_quantity_int * order_items_array[i].cost
            ).toFixed(2)}`
          );
          if (order_items_array[i].action != 'Add') {
            order_items_array[i].action = 'Update';
          }
          let promo_code_id = Number($('#promo_id').val());
          var isUpdateComponents = (discount_value == null || typeof discount_value === 'undefined' || discount_value == 0.0) || promo_code_id != 0
          updateCosts(isUpdateComponents);
          break;
        }
      }
      if(discount_value != null && typeof discount_value !== 'undefined' && discount_value != 0.0) {
        setTimeout(applyDiscount, 1200);
      }

  });

  $('#clear_discount_button').click(() => {
    order_discount = 0;
    discount_value = 0.0;
    discount_type = 'dollar';
    $('#discount').val('');
    $('#promoId').val('');
    $('#promoName').html('');
    $('#promo_code_search').val('');
    $("#apply_discount_button").prop("disabled", true);
    $("#apply_promo_button").prop("disabled", true);
    updateCosts();
    updateCost(order_items_array, order_items_array, true, false)

  });
  $('#reward').on('change', function(){
    reward_discount = $('#reward').val()
    if(reward_discount > (subtotal + delivery_cost)){
      alert('Discount amount can not be more then sub total amount');
    }else{
      updateCosts();
    }
  });

  $("#discount").on('keyup', function () {
    if(!$('#promoId').val().length) {
      $("#apply_discount_button").prop("disabled", false);
    } else {
      $('#discount').val('');
      alert("You can't have double discounts,so remove promo discount!");
    }
  });

  $('#apply_discount_button').click(() => {
    $('#discount-value').val($('#discount').val());
    applayDiscount();
  });

  function applayDiscount(){
    discount_value = Number($('#discount-value').val());
    discount_type = $("input[name='discount_type']:checked").val();
    promo_code_id = Number($('#promoId').val());
    const product_count = order_items_array.length;
    if (product_count >= 1 && promo_code_id == 0) {
      if (!isNaN(discount_value) && discount_value !== 0 && parseFloat(discount_value, 10) > 0.0) {
        if (discount_type == 'percentage') {
          order_discount = ((subtotal - excludedSubTotal) * discount_value) / 100;
        } else {
          order_discount = discount_value;
        }
        if(has_tax === false){
          if(order_discount === ((subtotal - excludedSubTotal) + delivery_cost)){
            updateCosts();
          } else if(order_discount > ((subtotal - excludedSubTotal) + delivery_cost)){
            alert('Discount amount can not be more then sub total amount');
          }else{
            updateCosts();
          }
        }else{
          if (order_discount > (subtotal - excludedSubTotal)) {
            alert('Discount amount can not be more then sub total amount');
          } else if (parseFloat(order_discount, 10) < 0.0) {
            alert('Please enter a positive number for discount amount!');
          } else {
            updateCosts();
          }
        }
      } else {
        alert('Please enter a number for discount amount!');
        $('#discount').val('');
      }
    }
  }

  $('#submit_button').click(() => {
    if(!$("#table_body tr").length) {
      alert('Product is missing!');
      return;
    }
    if (!$('input[name=payment_type]:checked').val()) {
      alert('Payment type is required');
      return false;
    }
    $('#submit_button').attr('disabled', true);

    const the_location_id = Number($('#store_location').val());
    const the_zone_id = Number($('#zone').val());

    var discount_type_value = $("input[name=discount_type]:checked").val()
    var discount_percent_value = 0.0
    var promo_code_id = $('#promoId').val();

    if(discount_type_value === 'percentage'){
      discount_percent_value = discount_value
    }

    const not_removed_items_array = [];
    order_items_array.forEach(item => {
      if (item.action !== 'Delete') {
        not_removed_items_array.push(item);
      }
    });

    const productDetails = _.map(not_removed_items_array, item => ({
      id: item.product_id,
      quantity: item.quantity,
      cost: item.product.sales_price ? item.product.sales_price : item.product.unit_price,
      cannabis: item.product.is_canabis,
      product_discount_price: item.product.product_discount_price,
      product_discount_quantity: item.product.product_discount_quantity,
      is_tax_excluded: item.product.tax_excluded
    })).flat();
    let current = new Date()
    let schedule_date = current.toLocaleDateString() + ' ' + $('#schedule_date').val()

    console.log('===========+++++++++++++++++++++++++order_items_array', order_items_array)

    const payload = _.omitBy(
      {
        order_items: JSON.stringify(order_items_array),
        productDetails: JSON.stringify(productDetails),
        order_id,
        discount: order_discount,
        discount_amount_reward: reward_discount,
        promo_code_id: promo_code_id,
        delivery: delivery_cost,
        comment: $('#comments').val(),
        delivery_address: $('#delivery_address').val(),
        apartment_number: $('#apartment_number').val(),
        lat: $('#latId').val(),
        lng: $('#lngId').val(),
        zip_code: $('#zip_code').val(),
        region: $('#order_region').val(),
        origin: $('#order_origin').val(),
        full_name: $('#full_name').val(),
        phone_number: $('#phone_number').val(),
        cost: $('#cost_shipping').find(':selected').data('cost'),
        cost_name:$('#cost_shipping').find(':selected').data('cost-name'),
        additional_charge: fee_amount,
        cc_fee,
        debit_fee,
        weedmap_fee,
        tax_id: $('#county_seat_id')
          .find(':selected')
          .val(),
        recreational_fee: recreationalFee,
        payment_type: $('input[name=payment_type]:checked').val(),
        location: the_location_id,
        zone: the_zone_id,
        order_total_cost,
        excise_tax: exciseTax,
        city_tax: city_tax_amount,
        business_city_tax: business_city_tax,
        city_tax_id: cityTaxId,
        sales_tax: salesTax,
        subtotal,
        requested_delivery_time: $('#requested_delivery_time').val(),
        discount_type: discount_type_value,
        discount_percent: discount_percent_value,
        in_store_pickup: inStorePickup,
        remove_limit: $('#remove_limit').is(':checked'),
        medical_order: $('#medical_order').is(':checked'),
        is_curbside: inStorePickup ? $('#is_curbside').is(':checked') : false,
        make: $('#make').val(),
        model: $('#model').val(),
        color: $('#color').val(),
        schedule_date: schedule_date
      },
      item => _.isNil(item) || item === ''
    );
    var is_medical_patient = $('#medical_order').is(':checked');
    var check_med = false;

    if(is_medical_patient) {

        const medical = _.omitBy(
          {
            expiration_date: $('#expiration_date').val(),
            user_id: $('#order_app_user_id').val(),
            medical_number_id: $('#medical_number_id').val()
          },
          item => _.isNil(item) || item === ''
        );

          $.post({url:'/medical_id_number', data: medical, async: false})
            .done(data => {
              if (_.isUndefined(data)) {
                return;
              }
              if (!data.success) {
                displayErrors(data.error);
                check_med = true;
              }
            })
            .fail(data => {
              if (get(data, 'responseJSON.error.message')) {
                const errorMessage = get(data, 'responseJSON.error.message');
                const errorElement = $('<li></li>').text(errorMessage);
                displayErrors(errorElement);
              }
            });
            if (check_med) {
              return;
            }

    }
    const url_address = $('.save_reason').attr('data-cost') ? '/exchange_orders/update' : '/update_order';

    $.post(url_address, payload)
      .done(data => {
        if (data.success == true) {
          if(inStorePickup){
            window.location.replace('/retail_orders');
          } else {
            window.location.replace('/orders');
          }
        } else if (data.isEdited == true) {
          if (!alert(data.message)) {
            window.location.reload();
          }
        } else {
          displayErrors(data.error);
          $('#submit_button').attr('disabled', false);
        }
        $('#customer_search').val(data.text);
        $('#edit_customer_modal').modal('hide');
      })
      .fail(data => {
        const errorMessage = $('<li></li>').html(
          get(data, 'responseJSON.error.message')
        );
        displayErrors(errorMessage);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        $('#submit_button').attr('disabled', false);
        $('#customer_search').val(data.text);
        $('#edit_customer_modal').modal('hide');
      });
  });

  function displayErrors(errors) {
    $('#order_errorMsg').html('There are error(s) in your submission.');
    $('#order_errorsUl').html(errors);
    $('#order_errors').show();
    $('#submit_button').removeAttr('disabled');
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  function hideBlocks(){
    var is_bundle = false;
    $('#table_body tr').each(function() {
      if ($(this).attr('data-bundle') == 'true') {
        is_bundle = true;
      }
    });

    if(is_bundle || !$('#table_body tr').length) {
      $('#discount_div').hide();
      $('#promoDetails').hide();
      $('#clear_discount').hide();
      $('#additional_div').hide();
      $('#weedmap_fee').hide();
      $("#clear_discount_button").trigger('click');
    } else {
      $('#discount_div').show();
      $('#promoDetails').show();
      $('#clear_discount').show();
      $('#additional_div').show();
      $('#weedmap_fee').show();
    }

  }

  function addItem(data, avail_qty) {
    const last_id = 0;
    const single_price_tag = getSinglePriceQtyTag(data, itemIndex, avail_qty);
    let is_bundle = data.is_bundle ? '| bundle |' : '';
    if (data.outdoor_price === 0 && !gon.is_catalyst) {
      data.outdoor_price = data.unit_price;
    }
    $('#table_body').append(
      `<tr data-bundle="${data.is_bundle}" id="row_${data.id}"><td>${data.brand_name} | ${data.name} ${is_bundle} (${avail_qty} in stock)` +
        `</td><td>${single_price_tag}</td><td>$${data.original_price}</td><td id="cost_${
          data.id
        }" data-qnty="1" data-cost="${data.outdoor_price.toFixed(2)}">$${data.outdoor_price.toFixed(2)}</td>` +
        `<td class="old-product-${$('.cancel_exchange').attr('data-id')}" data-id="${data.id}"><a id="remove_${data.id}" class="remove_button">Remove</a></td></tr>`
    );

    is_bundle = false;
    $('#table_body tr').each(function() {
      if ($(this).attr('data-bundle')) {
        is_bundle = true;
      }
    });
    const prod = {
      cost: data.unit_price,
      order_id,
      product: data,
      product_id: data.id,
      quantity: 1,
      single_price: true,
      is_canabis: data.is_canabis,
      action: 'Add'
    };
    if($('.save_reason').attr('data-cost')) {
      prod['exchange_count'] =  parseInt($('.exchange_count').val());
      prod['exchange_reason'] = $('#reasonId select').val();
      prod['old_product_id'] = $('.cancel_exchange').attr('data-id');
    }
    var exchange_cost = parseFloat($('#current_cost').attr('value')) + parseFloat(data.unit_price.toFixed(2));
    $('#current_cost').attr('value', exchange_cost);

    order_items_array.push(prod);
    var promo_code_id = Number($('#promo_id').val());
    var isUpdateComponents = (discount_value == null || typeof discount_value === 'undefined' || discount_value == 0.0) || promo_code_id != 0
    updateCosts(isUpdateComponents);
    if(discount_value != null && typeof discount_value !== 'undefined' && discount_value != 0.0 && !data.is_bundle) {
      setTimeout(applyDiscount, 1200);
    }
    hideBlocks();
  }
  function addTaxesToOrderItems(order_items, taxes) {
    $.each(order_items, function(index, val) {
      taxes.filter(function (el) {
        console.log('el.productIdentifier', el.productIdentifier)
        console.log('val.product_id', val.product_id)
        if (el.productIdentifier == val.product_id){
          console.log('order_items[index]', order_items[index]);
          console.log('order_items[index]', order_items[index]['cost_info']);
          console.log('el.salesTax', el.salesTax);

          order_items[index].cost_info =  {
            sales_tax: el.salesTax,
            excise_tax: el.exciseTax,
            city_tax:  el.cityTax,
          };
        }
      });
    });
  }
  function updateCost(order_items, new_order_items, data_product = false, promo = true) {
    if(promo){
      $.each(order_items, function(index, val) {
        new_order_items.filter(function (el) {
          if ((el.id == val.id) || (el.id == val.product_id && data_product)) {
            order_items[index].cost = el.cost;
            $('#cost_' + val.id).html('$' + el.cost)

          }
        });
      });
    }else{
      new_order_items.filter(function (el) {
         el.cost = el.product.sales_price ? el.product.sales_price : el.product.unit_price;
          $('#cost_' + el.product_id).html('$' + el.cost)

      });
    }

  }
  $('#promo_code_search')
    .autocomplete({
      selectFirst: true,
      minLength: 0,
      scroll: true,
      classes: {
        'ui-autocomplete': 'highlight-autocomplite'
      },
      source(request, response) {
        const brandId = $('#brandId').val();
        $.getJSON(
          '/promo_code_search',
          {
            term: request.term,
            marketplace_id: $('#store_marketplace').val(),
          },
          response
        );
      },
      select(e, ui) {
        $('#promoId').val(ui.item.value);
        $('#apply_promo_button').prop('disabled', false);
        $('#promo_code_search').val('');
        $('#promoName').html(ui.item.label);
        return false;
      }
    })
    .focus(function() {
      // reset result list's pageindex when focus on
      window.pageIndex = 0;
      const obj = $('#promo_code_search').val();
      $('#promo_code_search').val('');
      $(this).autocomplete('search', '');
      $('#promo_code_search').val(obj);
      $('#apply_discount_button').prop('disabled', true);
      $("#discount").val('');
    });

  $('#promo_code_search').on('input', function() {
    if (!$('#promo_code_search').val()) {
      $('#apply_promo_button').val('');
    }
  });


  $('#apply_promo_button').click(() => {
    if($("#order_app_user_id").val()) {
      updateCosts(true, false);
    } else {
      alert('Please select a customer first!');
    }
  });


$('#delete_button').click(() => {
  const deleteMessage =
    'Are you sure you want to delete this order? This action cannot be undone!';
  if (window.confirm(deleteMessage)) {
  }
});
});
